.login-box {
  background: #f3eaff;
  border-radius: 2px;
  bottom: 0;
  box-shadow: #969696 0 5px 38px;
  height: 600px;
  left: 0;
  margin: auto;
  width: 500px;
  padding: 0 30px;
  position: absolute;
  right: 0;
  top: 0;
}
.learnlink-logo {
  margin-top: 1em;
  width: 80%;
}
.subheading {
  font-size: 1.3em;
  margin-top: 40px;
  color: #4200a6;
  font-family: "Ubuntu", sans-serif;
}
#room_suggestion {
  margin: 3em 0 1em 0;
}
.room-name {
  justify-content: center;
  margin-top: 1em;
}
.v-btn {
  margin-top: -10em;
}
h1,
h2,
h3,
p,
label,
.room-name {
  color: #a669ff !important;
  font-family: "Ubuntu", sans-serif;
}
/*# sourceMappingURL=src/views/Home.css.map */