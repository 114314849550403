/* GLOBAL DESIGN RULES */
/* These rules should be identical in all Learnlink projects */
h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.5;
}
h1 {
  font-size: 2.2em;
  font-weight: 600;
  color: #3c9ee3;
}
@media screen and (max-width: 425px) {
}
h2 {
  font-size: 1.8em;
  font-weight: 400;
  color: #3078b0;
}
@media screen and (max-width: 425px) {
}
h3 {
  font-size: 1.5em;
  font-weight: 400;
  color: #286492;
}
@media screen and (max-width: 425px) {
}
h4 {
  font-size: 1.3em;
  font-weight: 700;
  color: #286492;
}
@media screen and (max-width: 425px) {
}
h5 {
  font-size: 1.2em;
  font-weight: 600;
  color: #286492;
}
@media screen and (max-width: 425px) {
}
h6 {
  font-size: 0.9em;
  font-weight: 600;
  color: #286492;
}
@media screen and (max-width: 425px) {
}
p {
  color: #696969;
}
.align-text-center {
  text-align: center !important;
}
.inline-list span:after {
  content: ', ';
}
.inline-list span:nth-last-of-type(2):after {
  content: ' og ';
}
.inline-list span:last-of-type:after {
  content: '';
}
.helptext {
  font-size: 12px;
  color: #989898;
}
.box {
  background: #fff;
  padding: 3% 5%;
}
@media screen and (max-width: 700px) {
  .box {
    margin: 0 !important;
    width: 100% !important;
    padding-top: 30px !important;
    box-shadow: none !important;
  }
}
pre {
  text-align: left;
  font-size: 10px;
}
/* GLOBAL DESIGN RULES END */
/*# sourceMappingURL=src/assets/learnlink.global.css.map */